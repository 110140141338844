<template>
    <div>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
            {{ text }}
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>

        <v-overlay :value="bookingLoad">
          <v-progress-circular
          indeterminate
          size="64"
          ></v-progress-circular>
        </v-overlay>

        <v-container class="px-0">
            <v-row justify="space-around" class="my-5">
              <v-btn elevation="0" class="rounded-lg" :text="step !== 1" color="primary" x-large @click="step = 1">Review Information</v-btn>
              <v-btn elevation="0" class="rounded-lg" :text="step !== 2" color="primary" x-large @click="step = 2">Traveller information</v-btn>
              <v-btn elevation="0" class="rounded-lg" :text="step !== 3" color="primary" x-large @click="nextClicked(3)">Payment</v-btn>
              <v-btn elevation="0" class="rounded-lg" :text="step !== 4" color="primary" x-large @click="step = 4">Confirmation</v-btn>
            </v-row>
            <div class="my-10">
              <div v-show="step === 1">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-form v-model="occupancyFormValid" ref="occupancyForm">
                      <h1 class="font-weight-bold">{{agencyTrip.name}}</h1>
                      <h2 class="primary--text">{{agencyTrip.price_per_person | currency}}</h2>
                      <div>
                        <div class="text-h6 mt-3">Trip Itinerary</div>
                        <p class="">{{agencyTrip.itinerary}}</p>
                      </div>
                      <div>
                        <div class="text-h6 mt-3">Trip Days</div>
                        <p>{{agencyTrip.days}} Days / {{agencyTrip.nights}} Nights</p>
                      </div>
                      <div>
                        <p class="text-h6 mt-3">Trip Date</p>
                        <v-menu
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            :value="date ? new Date(date.replaceAll('-', '/')).toDateString() : ''"
                            persistent-hint
                            v-bind="attrs"
                            outlined
                            append-icon="mdi-calendar"
                            flat
                            v-on="on"
                            placeholder="Start Date"
                            class="rounded-lg"
                            clearable
                            id="trip-start-date"
                            :full-width="false"
                            hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                          :nudge-bottom="0"
                          v-model="date"
                          @input="dateMenu = false"
                          :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      <v-row class="mt-10">
                        <v-col>
                          <p class="body-1 font-weight-bold">No. of adult travellers</p>
                          <v-text-field :rules="requiredRule" outlined type="number" v-model="adults" min="1" max="10"></v-text-field>
                        </v-col>
                        <v-col>
                          <p class="body-1 font-weight-bold">No. of children travellers</p>
                          <v-text-field :rules="requiredRule" outlined type="number" v-model="children" min="0" :max="adults"></v-text-field>
                        </v-col>
                        <v-col>
                          <p class="body-1 font-weight-bold">No. of infant travellers</p>
                          <v-text-field :rules="requiredRule" outlined type="number" v-model="infants" min="0" :max="adults"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                    <div class="text-center">
                      <v-btn x-large class="mx-5" color="white primary--text" @click="$router.go(-1)">Back to Details</v-btn>
                      <v-btn x-large class="mx-5 px-10" color="primary white--text" @click="step = 2">Next</v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card class="mb-5">
                      <div class="d-flex align-start pa-2">
                        <v-img :src="agencyTrip.image" max-width="150"></v-img>
                        <div>
                          <p class="body-1 font-weight-bold ml-5">{{agencyTrip.name}}</p>
                          <p class="body-2 grey--text font-weight-bold ml-5"><v-icon left>mdi-map-marker</v-icon>{{agencyTrip.city}}</p>
                        </div>
                      </div>
                      <v-card-title class="primary--text text-center text-bold headline ">
                        <p class="mx-auto my-0">Your Selected Trip</p>
                      </v-card-title>
                      <v-card-text class="px-0">
                        <div>
                          <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Start Date</p>
                          <div class="text-h6 font-weight-light text-center my-2">{{new Date(agencyTrip.date.replaceAll('-', '/')).toDateString()}}</div>
                        </div>
                        <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Initial Amount</p>
                        <v-row justify="space-between" class="ma-5 mb-1">
                          <p class="body-1 paragraph--text font-weight-bold mb-0 text-right">Price per Person</p>
                          <p class="body-1 paragraph--text font-weight-bold mb-0 text-right">{{agencyTrip.price_per_person | currency}}</p>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div v-show="step === 2">
                <v-container v-if="step === 2" class="">
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-form v-model="valid1" ref="form1">
                        <v-card outlined class="mb-5" v-for="n in Number(adults) + Number(children) + Number(infants)" :key="'traveller' + n">
                          <h2 v-if="n == 1"  class="paragraph--text pa-2 font-weight-bold">Traveller Info</h2>
                          <p v-if="n == 1" class="pl-2 paragraph--text">*Field Required</p>
                          <!-- <div>
                            <p>Are you travelling for work ?</p>
                            <v-radio-group
                            v-model="travellingForWork"
                            row
                            >
                              <v-radio
                              label="Yes"
                              :value="true"
                              ></v-radio>
                              <v-radio
                              label="No"
                              :value="false"
                              ></v-radio>
                            </v-radio-group>
                          </div> -->
                          <h2 class="paragraph--text pa-2 font-weight-bold">Traveller {{n}}, {{adults >= n ? `Adult ${infants >= n ? 'With infant in lap' : ''}` : (children >= ( n - adults) ? 'Child' : (infants >= (n - (adults + children)) ? 'Infant' : null))}}</h2>
                          <v-row class="pa-2 pb-5">
                            <v-col cols="12" sm="4">
                              <v-select
                              :items="['male', 'female']"
                              label="Gender"
                              outlined
                              :rules="requiredRule"
                              v-model="passengerGender[n-1]"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                              height="60"
                              hide-details
                              outlined
                              prepend-inner-icon="mdi-account"
                              v-model="passengerFirstName[n-1]"
                              :rules="nameRules"
                              label="First name"
                              required
                              color="blue"
                              :id="`passenger-${n-1}-firstName`"
                              ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="4">
                              <v-text-field
                              height="60"
                              hide-details
                              outlined
                              prepend-inner-icon="mdi-account"
                              v-model="passengerMiddleName[n]"
                              label="Middle name:Optional"
                              color="blue"
                              :id="`passenger-${n}-middleName`"
                              :rules="middleNameRules"
                              ></v-text-field>
                            </v-col> -->
                            <v-col cols="12" sm="4">
                              <v-text-field
                              height="60"
                              hide-details
                              outlined
                              v-model="passengerLastName[n-1]"
                              prepend-inner-icon="mdi-account"
                              :rules="nameRules"
                              label="Last name"
                              required
                              color="blue"
                              :id="`passenger-${n-1}-lastName`"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <date-picker
                              v-model="dateOfBirth[n-1]"
                              clearable
                              editable
                              valueType="YYYY-MM-DD"
                              placeholder="Birthday"
                              :default-value="adults >= n ? adultsMaxDate : ((children >= (n - adults)) ? childrenMinDate :  infantsMinDate)"
                              :disabled-date="adults >= n ? adultsBirthDateRange : ((children >= (n - adults)) ? childrenBirthDateRange :  infantsBirthDateRange)"
                              required
                              format="M/D/YYYY"
                              append-to-body
                              :input-attr="{id: 'birthDateId' + n}"
                              @focus="changeInputColor('birthDateId' + n, '#2196F3')"
                              @blur="changeInputColor('birthDateId' + n, '#9e9e9e')"
                              @input-error="changeInputColor('birthDateId', 'red')"
                              :id="`passenger-${n}-birthday`"
                              >
                              </date-picker>
                              <!-- <span class="caption grey--text">Hint: MM/DD/YYYY</span> -->
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                              height="60"
                              v-model="passNum[n-1]"
                              label="Passport Number"
                              prepend-inner-icon="mdi-passport"
                              outlined
                              :rules="passRules"
                              color="blue"
                              hide-details
                              :id="`passenger-passport-num-${n}`"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <date-picker
                              v-model="passExpireDate[n-1]"
                              clearable
                              editable
                              valueType="YYYY-MM-DD"
                              placeholder="Passport Expire Date"
                              :default-value="passportMinExpireDate"
                              :disabled-date="minExpireDateRange"
                              format="M/D/YYYY"
                              append-to-body
                              :input-attr="{id: 'passportExpiry' + n}"
                              @focus="changeInputColor('passportExpiry' + n, '#2196F3')"
                              @blur="changeInputColor('passportExpiry' + n, '#9e9e9e')"
                              @input-error="changeInputColor('passportExpiry' + n, 'red')"
                              :id="`passenger-passportExpiry-${n}`"
                              >
                              </date-picker>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-autocomplete
                                v-model="issueCountry[n-1]"
                                :items="countries"
                                item-text="name"
                                item-value="code"
                                placeholder="Issuing Country"
                                persistent-hint
                                name="issue-country-for-passports"
                                required
                                outlined
                                prepend-inner-icon="mdi-flag"
                                hide-no-data
                                color="blue"
                                autocomplete="off"
                                height="60"
                                hide-details
                                :menu-props="{ auto: true, maxWidth: 200, overflowY: true }"
                                :id="`passenger-passport-issue-country`"
                                ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-form>
                      <v-row justify="center" class="my-5">
                        <v-btn x-large class="mx-5 px-15" color="white primary--text" @click="step = 1">Previous</v-btn>
                        <v-btn x-large class="mx-5 px-15" color="primary white--text" @click="nextClicked(2)">Next</v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                    <v-card class="mb-5">
                      <div class="d-flex align-start pa-2">
                        <v-img :src="agencyTrip.image" max-width="150"></v-img>
                        <div>
                          <p class="body-1 font-weight-bold ml-5">{{agencyTrip.name}}</p>
                          <p class="body-2 grey--text font-weight-bold ml-5"><v-icon left>mdi-map-marker</v-icon>{{agencyTrip.city}}</p>
                        </div>
                      </div>
                      <v-card-title class="primary--text text-center text-bold headline ">
                        <p class="mx-auto my-0">Your Selected Trip</p>
                      </v-card-title>
                      <v-card-text class="px-0">
                        <div>
                          <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Start Date</p>
                          <div class="text-h6 font-weight-light text-center my-2">{{new Date(agencyTrip.date.replaceAll('-', '/')).toDateString()}}</div>
                        </div>
                        <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Initial Amount</p>
                        <v-row justify="space-between" class="ma-5 mb-1">
                          <p class="body-1 paragraph--text font-weight-bold mb-0 text-right">Price per Person</p>
                          <p class="body-1 paragraph--text font-weight-bold mb-0 text-right">{{agencyTrip.price_per_person | currency}}</p>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-show="step === 3">
                <v-container>
                  <v-row class="px-1">
                    <v-col cols="12" md="8">
                      <v-card outlined class="pa-5 my-5">
                        <h2 class="paragraph--text font-weight-bold pa-0">Trip Price</h2>
                        <h3 class="primary--text my-2">{{agencyTrip.price_per_person}} EGP</h3>
                        <p class="red--text">Please click on the URL below to pay</p>
                        <h2 class="paragraph--text font-weight-bold mb-2">Payment Link</h2>
                        <v-btn v-for="item in agencyTrip.payment_link" :key="item" elevation="1" tile color="white my-2 blue--text">{{item}}</v-btn>
                      </v-card>
                      <!-- <v-row justify="center" class="my-5">
                        <v-btn x-large class="mx-5 px-15" color="primary white--text" >Complete Booking</v-btn>
                      </v-row> -->
                      <v-row justify="center" class="my-5">
                        <v-btn x-large class="mx-5 px-15" color="white primary--text" @click="step = 2">Check Booking details</v-btn>
                        <v-btn x-large class="mx-5 px-15" color="primary white--text" @click="nextClicked(3)">Complete Booking</v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card class="mb-5">
                      <div class="d-flex align-start pa-2">
                        <v-img :src="agencyTrip.image" max-width="150"></v-img>
                        <div>
                          <p class="body-1 font-weight-bold ml-5">{{agencyTrip.name}}</p>
                          <p class="caption grey--text font-weight-bold ml-5"><v-icon left>mdi-map-marker</v-icon>{{agencyTrip.city}}</p>
                        </div>
                      </div>
                      <v-card-title class="primary--text text-center text-bold headline ">
                        <p class="mx-auto my-0">Your Selected Trip</p>
                      </v-card-title>
                      <v-card-text class="px-0">
                        <div>
                          <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Start Date</p>
                          <div class="text-h6 font-weight-light text-center my-2">{{new Date(agencyTrip.date.replaceAll('-', '/')).toDateString()}}</div>
                        </div>
                        <p class="grey lighten-2 paragraph--text text-center text-h6 font-weight-regular mb-0">Initial Amount</p>
                        <v-row justify="space-between" class="ma-5 mb-1">
                          <p class="body-1 paragraph--text font-weight-bold mb-0 text-right">Price per Person</p>
                          <p class="body-1 paragraph--text font-weight-bold mb-0 text-right">{{agencyTrip.price_per_person | currency}}</p>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-show="step === 4">
                <v-container>
                  <v-card elevation="3" :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'" class="mx-auto pa-5">
                    <div class="text-center">
                      <v-icon size="72" color="success">mdi-check-circle</v-icon>
                    </div>
                    <h3 class="my-3 paragraph--text text-center">Payment Complete</h3>
                  </v-card>
                  <h2 class="my-10 paragraph--text text-center font-weight-light">Thank you, Your details have been submitted successfully, An agent will contact you shortly to complete your booking!</h2>
                  <v-btn
                  color="primary white--text px-15"
                  large
                  @click="$router.push({ name: 'Home' })"
                  class="my-5 d-block mx-auto"
                  elevation="0"
                  >
                    OK
                  </v-btn>
                </v-container>
              </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { countries } from '@/constants.js'
import user from '../../web_services/user'

export default {
  title: '-Book',
  data () {
    return {
      step: 1,
      dateMenu: false,
      birthDateMenu: [],
      date: '',
      adults: 1,
      children: 0,
      infants: 0,
      occupancyFormValid: true,
      valid1: true,
      loaded: true,
      snackbar: false,
      color: '',
      text: '',
      travellingForWork: false,
      passengers: [],
      name: '',
      nameRules: [v => (!!v && v.length > 1) || 'Item is required at least 2 characters',
        v => /^[A-Za-z][A-Za-z]*$/.test(v) || 'Name Must be letters only with no spaces'
      ],
      middleNameRules: [
        v => (/^[A-Za-z][A-Za-z]*$/.test(v) || !v) || 'Name Must be letters only with no spaces'
      ],
      requiredRule: [
        v => !!v || 'Field is required'
      ],
      passRules: [
        v => !!v || 'Passport number is required'
      ],
      passengerFirstName: [],
      passengerMiddleName: [],
      passengerLastName: [],
      passengerGender: [],
      dateOfBirth: [],
      passExpireDate: [],
      passExpireDateText: [],
      minExpireDate: [],
      passNum: [],
      issueCountry: [],
      travellersData: [],
      passportExpiry: null,
      confirmData: {},
      bookingLoad: false
    }
  },
  computed: {
    ...mapState(['agencyTrip']),
    countries () {
      return countries
    },
    adultsMaxDate () {
      const today = new Date()
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const max = today.setFullYear(year - 12)
      return max
    },
    childrenMaxDate () {
      const today = new Date()
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const max = today.setFullYear(year - 2)
      return max
    },
    childrenMinDate () {
      const today = new Date()
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const min = today.setFullYear(year - 12)
      return min
    },
    infantsMinDate () {
      const today = new Date()
      const year = today.getFullYear()
      today.setDate(today.getDate())
      const min = today.setFullYear(year - 2)
      return min
    },
    passportMinExpireDate () {
      const today = new Date()
      today.setMonth(today.getMonth() + 6)
      return today
    }
  },
  methods: {
    adultsBirthDateRange (date) {
      return date > new Date(this.adultsMaxDate) || date < new Date('1900-1-1')
    },
    childrenBirthDateRange (date) {
      return date > new Date(this.childrenMaxDate) || date < new Date(this.childrenMinDate)
    },
    infantsBirthDateRange (date) {
      const today = new Date()
      return date > today || date < new Date(this.infantsMinDate)
    },
    minExpireDateRange (date) {
      return date < new Date(this.passportMinExpireDate)
    },
    changeInputColor (id, color) {
      document.getElementById(id).style.borderColor = color
    },
    prepareTravellersData () {
      this.travellersData = []
      for (let index = 0; index < this.adults; index++) {
        let type
        if (this.adultsNum >= (index + 1)) {
          type = 'ADT'
        } else if (this.childrenNum >= ((index + 1) - this.adultsNum)) {
          type = 'CNN'
        } else if (this.infantsNum >= ((index + 1) - (this.adultsNum + this.childrenNum))) {
          type = 'INF'
        }
        this.travellersData.push({
          type: type,
          gender: this.passengerGender[index],
          first_name: this.passengerFirstName[index],
          last_name: this.passengerLastName[index],
          birth_of_date: this.getBirthdate(this.dateOfBirth[index]),
          passport_number: this.passNum[index] ? this.passNum[index] : '',
          passport_expiry_date: this.passExpireDate[index] ? this.passExpireDate[index] : '',
          passport_issue_country: this.issueCountry[index] ? this.issueCountry[index] : ''
        })
      }
    },
    getBirthdate (date) {
      if (date) {
        let newDate = ''
        const segments = date.replace(/\//g, '-').split('-')
        if (segments[0].length === 1) segments[0] = `0${segments[0]}`
        if (segments[1].length === 1) segments[1] = `0${segments[1]}`
        newDate = `${segments[0]}-${segments[1]}-${segments[2]}`
        return newDate
      }
    },
    nextClicked (currentPage) {
      try {
        if (currentPage === 1) {
          this.currentPage = 1
          window.scrollTo(0, 0)
          this.$refs.occupancyForm.validate()
          if (this.occupancyFormValid) {
            this.snackbar = false
            this.step = 2
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to continue'
          }
        } else if (currentPage === 2) {
          this.currentPage = 2
          window.scrollTo(0, 0)
          this.$refs.form1.validate()
          if (this.valid1) {
            this.snackbar = false
            this.prepareTravellersData()
            this.nextLabel = 'Book'
            this.step = 3
          } else {
            this.snackbar = true
            this.color = 'warning'
            this.text = 'Please fill all fields to continue'
          }
        } else if (currentPage === 3) {
          this.currentPage = 3
          this.book()
        } else if (currentPage === 4) {
          this.currentPage = 4
          window.scrollTo(0, 0)
          this.$router.push({ name: 'Home' })
        }
      } catch (error) {}
    },
    backClicked (currentPage) {
      this.currentPage = currentPage
      if (currentPage === 4) {
        return false
      } else if (currentPage === 3 || currentPage === 2) {
        window.scrollTo(0, 0)
        this.nextLabel = 'Continue'
        return true
      } else return true
    },
    book () {
      const payload = {
        trip_id: this.agencyTrip.id,
        travel_date: this.date,
        number_of_adults: this.adults,
        number_of_children: this.children,
        number_of_infants: this.infants,
        payment_link: this.agencyTrip.payment_link[0],
        paid_price: this.agencyTrip.price_per_person,
        passengerDetails: this.travellersData
      }
      this.bookingLoad = true
      user.tripBook(payload).then(res => {
        this.step = 4
      }).catch((error) => {
        this.snackbar = true
        this.color = 'error'
        let text = ''
        if (error.response.data.errors && error.response.data.errors.length) {
          Object.entries(error.response.data.errors).forEach(([key, value]) => {
            value.forEach(item => {
              text += item + ' ! '
            })
          })
        } else text = error.response.data.message
        this.text = text
      }).finally(() => {
        this.bookingLoad = false
      })
    },
    goTo (page) {
      const routeData = this.$router.resolve({ name: page })
      window.open(routeData.href, '_blank')
    },
    closeTab () {
      this.$router.push({ name: 'Home' })
    }
  },
  created () {
    if (!this.agencyTrip.id) this.$router.go(-1)
    this.date = this.agencyTrip.date
  }
}
</script>

<style>
  .mx-datepicker {
    margin-bottom: 0px;
  }
  /* confirmartion */
  .confirm-card {
    padding: 20px;
    text-align: center;
    background-color: rgb(223, 223, 223);
    background-size: contain;
    background-position: top;
  }
  .confirm-card_overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 700px;
    background-image: linear-gradient(45deg, #2f80c2, #0a3c65f0);
    padding: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-shadow: 0px 1px 3px rgb(35 35 35);
    padding: 20px 20%;
  }
  .confirm-card_overlay h3 {
    font-size: 40px;
  }
</style>
